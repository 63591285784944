/********** Général **********/

body,
html {
    font-family: 'Merriweather Sans', sans-serif;
    background-image: url('https://www.clermont-toit.fr/Assets/Images/background.jpg');
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    color: #000;
    line-height: 1.2;
    font-weight: 300;
}

.separateur {
    background-image: url('https://www.clermont-toit.fr/Assets/Images/bg-trait.png');
    background-position: center;
    height: 2px;
    margin: 20px 0 !important;
}


.sliding-menu-opened {
    visibility: visible;
    right: 0;
    z-index: -1;
    transition: right .3s .2s ease-in-out;
}


.nav-menu li a {
    color: white;
}

.nav-menu li a:hover {
    background-color: #26a246;
    color: #FFF;
    border-radius: 20px;
}



/********** Medias Queries **********/


@media (min-width: 768px) {
    .header {
        display: flex;
        height: 90px;
    }

    .container-left {
        display: flex;
        width: 25%;
        background-color: #FFF;
    }

    .container-right {
        background: #6c757d;
    }

    .container-left-info {
        display: block;
    }

    .top-info {
        visibility: visible;
    }

    ul.nav-menu {
        list-style-type: none;
        margin: 35px 0;
    }


    .nav-menu li {
        padding-right: 20px;
    }

    .nav-menu li a {
        font-weight: 900;
        color: #26a246;
    }

    a#link {
        visibility: hidden;
    }

    img.logo {
        margin-top: 5px;
    }

    .phone a {
        color: #26a246;
        font-weight: bold;
        margin-left: 10px;
        text-decoration: none;
    }

    .nav-container {
        border-top: solid 1px #26a246;
    }

    .nav-menu {
        flex-direction: row;
        background: none;
        visibility: visible;
        position: absolute;
        width: 20%;
        right: 25%;
        top: 0;

    }

    .logo-footer {
        display: flex;
        height: 150px;
        margin-right: 2%;
        margin-left: -10%;
    }

    p.days {
        margin-bottom: 0;
    }

    .container-mentions {
        padding-bottom: 10px;
        padding-top: 2%;
        margin-right: 50%;
        margin-left: 0;
    }

    .collapsible-form {
        right: 1%;

    }

    .row {
        display: flex;
    }

    .logo-footer {
        margin-right: 12%;
        margin-left: -10%;
    }

    .infos {
        display: flex;
        margin-left: -5%;
    }

    .infos-siege {
        margin-top: 5%;
        margin-left: 10%;
        margin-right: 0;
    }

    .footer-schedules {
        margin-top: 30px;
        margin-left: 0;
        margin-right: 10%;
    }

    /* .card {
        width: 320px;
    } */

}
